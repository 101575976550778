* {
  font-family: "Roboto";
  font-weight: 500;
}

a {
  color: rgb(102, 102, 208);
  cursor: pointer;
  text-decoration: unset;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  justify-content: center;
}

.layout {
  display: grid;
  width: 1440px;
  grid-template-areas: "sider header"
  "sider content";
  grid-template-columns: 256px 1fr;
  grid-template-rows: 97px calc(100vh - 97px);
}

.sider {
  grid-area: sider;
}

.header {
  display: grid;
  grid-template-columns: 250px 1fr 1fr;
  background-color: rgb(251, 252, 255);
  grid-area: header;
  align-content: center;
}

.content{
  background-color: rgb(251, 252, 255);
  grid-area: content;
}

.menu {
  display: grid;
  grid-template-areas: "logo"
  "menu-elements";
}

.logo {
  background: url('/public/logo.png') left no-repeat;
  margin-top: 10px;
  height: 71px;
  margin-left: 17px;
  grid-area: logo;
}

.menu-elements {
  grid-area: menu-elements;
}

.menu-element {
  display: flex;
}

.menu-element > div:first-child {
  width: 4px;
}

.menu-element > .icon {
  width: 64px;
  height: 50px;
}

.menu-element > .text {
  display: grid;
  align-items: center;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(52, 52, 52);
}

.menu-element > .text-selected {
  display: grid;
  align-items: center;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(125, 125, 125);
}

.menu-element-selected {
  background-color: rgb(102, 102, 204);
  box-shadow: 0px 2px 10px 0px rgba(102, 102, 204, 0.67);
}

.crumbs {
  display: grid;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(101, 101, 101);
  text-align: left;
  align-content: flex-start;
  width: 176px;
  height: 54px;
  align-items: center;
  padding-left: 59px;
}

.envelope-holder {
  display: flex;
  justify-content: flex-end;
  /* gap: 16px; */
  padding-right: 16px;
}

.envelope {
  border-width: 1px;
  border-color: rgb(212, 212, 212);
  border-style: solid;
  border-radius: 5px;
  width: 63px;
  height: 63px;
  background: url('/public/envelope1.png') center no-repeat;
}

.ellipse {
  border-style: solid;
  border-width: 3px;
  border-color: rgb(255, 255, 255);
  border-radius: 50%;
  background-color: rgb(228, 38, 38);
  width: 7px;
  height: 7px;
  margin-top: 15px;
  margin-left: 37px;
}

.profile {
  display: flex;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 16px 0px rgba(136, 167, 245, 0.18);
  width: 256px;
  height: 65px;
  z-index: 9;
}

.profile > .holder {
  display: grid;
  align-items: center;
  height: 100%;
  width: 83px;
  justify-content: center;
}

.profile > .holder > .foto {
  border-width: 3px;
  border-color: rgb(212, 212, 212);
  border-style: solid;
  border-radius: 50%;
  background-color: rgb(244, 192, 2);
  background: url('/public/layer_1_0.png') center no-repeat;
  width: 39px;
  height: 39px;
}

.profile > .text {
  display: grid;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(125, 125, 125);
  align-items: center;
  justify-items: start;
}

.conteiner {
  padding-left: 59px;
}

.button {
  display: flex;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Roboto";
  font-width: 500;
  color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
}

.conteiner > .button > icon {
  width: 70px;
}

.modal {
  position: absolute;
  display: grid;
  grid-template-areas: "modal-header"
  "modal-content"
  "modal-footer";
  grid-template-columns: 1fr;
  grid-template-rows: 73px 1fr 106px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 30px 38px 0px rgba(37, 37, 37, 0.1);
  width: 617px;
  height: 444px;
  margin-top: 19px;
}

.modal > .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 73px;
  padding-left: 29px;
  grid-area: modal-header;
}

.modal > .modal-header > .title {
  font-size: 20px;
  color: rgb(52, 52, 52);
  text-align: left;
}

.modal > .modal-header > .title {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(52, 52, 52);
  text-align: left;
}

.modal > .modal-header > .close {
  width: 62px;
  height: 62px;
  text-align: right;
}

.modal > .modal-content {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "custom"
  "domen"
  "info";
  grid-area: modal-content;
  grid-row-gap: 9px;
  padding-top: 24px;
}

.modal > .modal-content > .sample {
  grid-area: sample;
}

.modal > .modal-content > .сolor-scheme {
  grid-area: сolor-scheme;
}

.modal > .modal-content > .domen {
  grid-area: domen;
  padding: 0px 30px;
}

.modal > .modal-content > .domen > .domen-conteiner {
  display: flex;
  margin-top: 6px;
}

.modal > .modal-content > .domen > .domen-conteiner > .placeholder {
  border-radius: 2px;
  background-color: rgb(230, 230, 233);
  width: 393px;
  height: 36px;
  display: flex;
  align-items: center;
}


.modal > .modal-content > .info {
  display: grid;
  grid-area: info;
  padding: 0px 30px;
  grid-template-columns: 76px 1fr;
  justify-content: space-between;
}

.modal > .modal-content > .info > .icon {
  background: url("/public/warning.png") center center no-repeat;
  width: 58px;
  height: 76px;
}

.modal > .modal-content > .info > .text {
  font-size: 12px;
  color: rgb(125, 125, 125);
  height: 76px;
}

.modal > .modal-footer {
  display: flex;
  align-content: center;
  align-items: center;
}

.modal > .modal-footer {
  display: flex;
  align-content: center;
  align-items: center;
  padding-left: 32px;
  gap: 10px;
}

.caption {
  font-size: 14px;
  font-family: "Roboto";
  color: rgb(52, 52, 52);
  text-align: left;
}

.input-holder {
  display: flex;
  border-width: 1px;
  border-color: rgb(205, 205, 205);
  border-style: solid;
  border-radius: 3px;
  width: 238px;
  height: 34px;
  margin-top: 6px;
}

.down-arrow {
  width: 30px;
  background: url('/public/arrow-down.png') center no-repeat;
}

.placeholder {
  align-self: center;
  width: 208px;
  padding-left: 13px;
  font-size: 12px;
  color: rgb(124, 124, 124);
}

.custom {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
}

.headline {
  font-size: 20px;
  color: rgb(52, 52, 52);
  height: 87px;
  display: flex;
  align-items: center;
}

.table-holder {
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 11px 21px 0px rgba(40, 40, 40, 0.07);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 59px;
  padding: 0px 30px;
}

.table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(3, 60px);
  row-gap: -1px;
  align-items: center;
}

.button-holder {
  display: flex;
  gap: 20px
}

.table > .text-data {
  font-size: 14px;
  color: rgb(110, 110, 110);
}

.table > .text-caption {
  font-size: 14px;
  color: rgb(52, 52, 52);
}
